<template>
    <v-container>
        <Header
            :registryStatus="getServicePricesStatuses"
            :recordIndex="getServicePricesDetails.index"
            :registryTitle="getServicePricesRegistry.name"
            :registryUrl="'service-prices'"
            :actualStatus="getServicePricesDetails.status"
            @status-changed="changeStatus"
            :avatarData="getServicePricesDetails.createdBy"
        />
        <Main class="p-relative">
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('service-prices:editionPrice')"
                    :open="open"
                     :width="'1100px'"
                    :height="'670'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editPrice()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('service-prices:editPrice') }}</span>
                    </v-tooltip>
                </div>
                <span></span>
            </div>
        </Main>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import Information from './../../../components//Registries/Service-prices/Details/Information'
import Buttons from './../../../components//Registries/Service-prices/Modal/Buttons'
import AEContent from './../../../components//Registries/Service-prices/Modal/Content'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: { Information, Buttons, AEContent },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        ...mapGetters([
            'getServicePricesDetails',
            'getServicePricesRegistry',
            'getServicePricesStatuses',
        ]),
    },
    methods: {
        ...mapMutations(['clearServicePricesErrors', 'setServicePricesModal']),
        ...mapActions([
            'fetchServicePrices',
            'fetchUsers',
            'updateServicePricesStatus',
        ]),
        async editPrice() {
            this.open = true
            this.clearServicePricesErrors()
            this.setServicePricesModal()
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        changeStatus(status) {
            this.updateServicePricesStatus({
                status,
                id: this.getServicePricesDetails._id,
            })
        },
        closeModal() {
            this.clearServicePricesErrors()
            this.open = false
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchServicePrices', { id: to.params.id, next })
    },
}
</script>
