<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <span class="pb-5"
                            ><b>{{ `${$t('service-prices:packietName')}` }}:</b>
                            {{ getServicePricesDetails.name }}
                        </span>
                        <span
                            >{{ `${$t('service-prices:description')}` }}:
                            {{ getServicePricesDetails.description }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="pa-5">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('service-prices:informacion')" />
                    </v-col>
                </v-row>
                <v-row
                    ><v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="getServicePricesDetails.name"
                            :label="`${$t('service-prices:name')}`"
                        ></v-text-field> </v-col
                ></v-row>
                <v-row
                    ><v-col cols="12"
                        ><v-textarea
                            :label="`${$t('service-prices:description')}`"
                            :value="getServicePricesDetails.description"
                            rows="1"
                            auto-grow
                        ></v-textarea></v-col
                ></v-row>
                <v-row
                    ><v-col cols="12">
                        <v-row>
                            <v-col>
                                <money-field
                                    readonly
                                    :value="
                                        getServicePricesDetails.survey.price
                                    "
                                    :label="`${$t(
                                        'service-prices:survey.default'
                                    )}`"
                                ></money-field>
                            </v-col>
                            <v-col cols="1">
                                <v-text-field
                                    readonly
                                    :value="`${$t(
                                        `service-prices:${$get(
                                            getServicePricesDetails.survey,
                                            'value',
                                            ''
                                        )}`
                                    )}`"
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('service-prices:unit')}`"
                                ></v-text-field
                            ></v-col>
                            <v-col>
                                <money-field
                                    readonly
                                    :value="
                                        getServicePricesDetails.repair.price
                                    "
                                    :label="`${$t(
                                        'service-prices:repair.default'
                                    )}`"
                                ></money-field>
                            </v-col>
                            <v-col cols="1">
                                <v-text-field
                                    readonly
                                    :value="`${$t(
                                        `service-prices:${$get(
                                            getServicePricesDetails.repair,
                                            'value',
                                            ''
                                        )}`
                                    )}`"
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('service-prices:unit')}`"
                                ></v-text-field
                            ></v-col>

                            <v-col offset="1" cols="2">
                                <money-field
                                    :label="`${$t(
                                        'service-prices:rateOneKmTransport'
                                    )}`"
                                    v-model="
                                        getServicePricesDetails.rateOneKmTransport
                                            .price
                                    "
                                ></money-field>
                            </v-col>
                            <v-col cols="2">
                                <money-field
                                    :label="`${$t(
                                        'service-prices:staticRateTransport'
                                    )}`"
                                    v-model="
                                        getServicePricesDetails
                                            .staticRateTransport.price
                                    "
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row
                    ><v-col>
                        <v-row>
                            <v-col cols="2">
                                <money-field
                                    readonly
                                    :value="
                                        getServicePricesDetails.installation
                                            .price
                                    "
                                    :label="`${$t(
                                        'service-prices:installation.default'
                                    )}`"
                                ></money-field>
                            </v-col>
                            <v-col cols="1">
                                <v-text-field
                                    readonly
                                    :value="`${$t(
                                        `service-prices:${$get(
                                            getServicePricesDetails.installation,
                                            'value',
                                            ''
                                        )}`
                                    )}`"
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('service-prices:unit')}`"
                                ></v-text-field
                            ></v-col>

                            <v-col cols="2">
                                <money-field
                                    readonly
                                    :value="
                                        getServicePricesDetails.diagnostics
                                            .price
                                    "
                                    :label="`${$t(
                                        'service-prices:diagnostics.default'
                                    )}`"
                                ></money-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    readonly
                                    :value="`${$t(
                                        `service-prices:${$get(
                                            getServicePricesDetails.diagnostics,
                                            'value',
                                            ''
                                        )}`
                                    )}`"
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('service-prices:unit')}`"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="2" offset="1">
                                <v-checkbox
                                    :label="`${$t(
                                        'service-prices:minRateTransport'
                                    )}`"
                                    v-model="
                                        getServicePricesDetails.minRateTransport
                                            .value
                                    "
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="2">
                                <money-field
                                    v-if="
                                        getServicePricesDetails.minRateTransport
                                            .value
                                    "
                                    :label="`${$t(
                                        'service-prices:minRateTransport'
                                    )}`"
                                    v-model="
                                        getServicePricesDetails.minRateTransport
                                            .price
                                    "
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row
                    ><v-col cols="7">
                        <v-row>
                            <v-col cols="3">
                                <money-field
                                    readonly
                                    :value="
                                        getServicePricesDetails.itService.price
                                    "
                                    :label="`${$t(
                                        'service-prices:itService.default'
                                    )}`"
                                ></money-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    readonly
                                    :value="`${$t(
                                        `service-prices:${$get(
                                            getServicePricesDetails.itService,
                                            'value',
                                            ''
                                        )}`
                                    )}`"
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('service-prices:unit')}`"
                                ></v-text-field
                            ></v-col>
                            <v-col>
                                <money-field
                                    readonly
                                    :value="
                                        getServicePricesDetails.remote.price
                                    "
                                    :label="`${$t(
                                        'service-prices:remote.default'
                                    )}`"
                                ></money-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    readonly
                                    :value="`${$t(
                                        `service-prices:${$get(
                                            getServicePricesDetails.remote,
                                            'value',
                                            ''
                                        )}`
                                    )}`"
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('service-prices:unit')}`"
                                ></v-text-field
                            ></v-col>
                        </v-row> </v-col
                    ><v-col offset="1">
                        <v-row>
                            <v-col>
                                <money-field
                                    :label="`${$t(
                                        'service-prices:ownManHoursCost'
                                    )}`"
                                    v-model="
                                        getServicePricesDetails.ownManHoursCost
                                            .price
                                    "
                                ></money-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    :rules="precentagesRules"
                                    type="number"
                                    :label="`${$t(
                                        'service-prices:partsDiscountInPercent'
                                    )}`"
                                    v-model="
                                        getServicePricesDetails
                                            .partsDiscountInPercent.price
                                    "
                                ></v-text-field
                            ></v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            precentagesRules: [
                v =>
                    (v && v >= 0 && v <= 100) ||
                    'Liczba musi zawierać się pomiędzy 0 a 100',
            ],
        }
    },
    computed: {
        ...mapGetters(['getServicePricesDetails']),
    },
}
</script>
<style scoped></style>
