var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('span',{staticClass:"pb-5"},[_c('b',[_vm._v(_vm._s(`${_vm.$t('service-prices:packietName')}`)+":")]),_vm._v(" "+_vm._s(_vm.getServicePricesDetails.name)+" ")]),_c('span',[_vm._v(_vm._s(`${_vm.$t('service-prices:description')}`)+": "+_vm._s(_vm.getServicePricesDetails.description)+" ")])])],1)],1),_c('v-col',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service-prices:informacion')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getServicePricesDetails.name,"label":`${_vm.$t('service-prices:name')}`}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t('service-prices:description')}`,"value":_vm.getServicePricesDetails.description,"rows":"1","auto-grow":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"readonly":"","value":_vm.getServicePricesDetails.survey.price,"label":`${_vm.$t(
                                    'service-prices:survey.default'
                                )}`}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$t(
                                    `service-prices:${_vm.$get(
                                        _vm.getServicePricesDetails.survey,
                                        'value',
                                        ''
                                    )}`
                                )}`,"item-text":"name","item-value":"value","label":`${_vm.$t('service-prices:unit')}`}})],1),_c('v-col',[_c('money-field',{attrs:{"readonly":"","value":_vm.getServicePricesDetails.repair.price,"label":`${_vm.$t(
                                    'service-prices:repair.default'
                                )}`}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$t(
                                    `service-prices:${_vm.$get(
                                        _vm.getServicePricesDetails.repair,
                                        'value',
                                        ''
                                    )}`
                                )}`,"item-text":"name","item-value":"value","label":`${_vm.$t('service-prices:unit')}`}})],1),_c('v-col',{attrs:{"offset":"1","cols":"2"}},[_c('money-field',{attrs:{"label":`${_vm.$t(
                                    'service-prices:rateOneKmTransport'
                                )}`},model:{value:(
                                    _vm.getServicePricesDetails.rateOneKmTransport
                                        .price
                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesDetails.rateOneKmTransport
                                        , "price", $$v)},expression:"\n                                    getServicePricesDetails.rateOneKmTransport\n                                        .price\n                                "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{attrs:{"label":`${_vm.$t(
                                    'service-prices:staticRateTransport'
                                )}`},model:{value:(
                                    _vm.getServicePricesDetails
                                        .staticRateTransport.price
                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesDetails
                                        .staticRateTransport, "price", $$v)},expression:"\n                                    getServicePricesDetails\n                                        .staticRateTransport.price\n                                "}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{attrs:{"readonly":"","value":_vm.getServicePricesDetails.installation
                                        .price,"label":`${_vm.$t(
                                    'service-prices:installation.default'
                                )}`}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$t(
                                    `service-prices:${_vm.$get(
                                        _vm.getServicePricesDetails.installation,
                                        'value',
                                        ''
                                    )}`
                                )}`,"item-text":"name","item-value":"value","label":`${_vm.$t('service-prices:unit')}`}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{attrs:{"readonly":"","value":_vm.getServicePricesDetails.diagnostics
                                        .price,"label":`${_vm.$t(
                                    'service-prices:diagnostics.default'
                                )}`}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$t(
                                    `service-prices:${_vm.$get(
                                        _vm.getServicePricesDetails.diagnostics,
                                        'value',
                                        ''
                                    )}`
                                )}`,"item-text":"name","item-value":"value","label":`${_vm.$t('service-prices:unit')}`}})],1),_c('v-col',{attrs:{"cols":"2","offset":"1"}},[_c('v-checkbox',{attrs:{"label":`${_vm.$t(
                                    'service-prices:minRateTransport'
                                )}`},model:{value:(
                                    _vm.getServicePricesDetails.minRateTransport
                                        .value
                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesDetails.minRateTransport
                                        , "value", $$v)},expression:"\n                                    getServicePricesDetails.minRateTransport\n                                        .value\n                                "}})],1),_c('v-col',{attrs:{"cols":"2"}},[(
                                    _vm.getServicePricesDetails.minRateTransport
                                        .value
                                )?_c('money-field',{attrs:{"label":`${_vm.$t(
                                    'service-prices:minRateTransport'
                                )}`},model:{value:(
                                    _vm.getServicePricesDetails.minRateTransport
                                        .price
                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesDetails.minRateTransport
                                        , "price", $$v)},expression:"\n                                    getServicePricesDetails.minRateTransport\n                                        .price\n                                "}}):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('money-field',{attrs:{"readonly":"","value":_vm.getServicePricesDetails.itService.price,"label":`${_vm.$t(
                                    'service-prices:itService.default'
                                )}`}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$t(
                                    `service-prices:${_vm.$get(
                                        _vm.getServicePricesDetails.itService,
                                        'value',
                                        ''
                                    )}`
                                )}`,"item-text":"name","item-value":"value","label":`${_vm.$t('service-prices:unit')}`}})],1),_c('v-col',[_c('money-field',{attrs:{"readonly":"","value":_vm.getServicePricesDetails.remote.price,"label":`${_vm.$t(
                                    'service-prices:remote.default'
                                )}`}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$t(
                                    `service-prices:${_vm.$get(
                                        _vm.getServicePricesDetails.remote,
                                        'value',
                                        ''
                                    )}`
                                )}`,"item-text":"name","item-value":"value","label":`${_vm.$t('service-prices:unit')}`}})],1)],1)],1),_c('v-col',{attrs:{"offset":"1"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                    'service-prices:ownManHoursCost'
                                )}`},model:{value:(
                                    _vm.getServicePricesDetails.ownManHoursCost
                                        .price
                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesDetails.ownManHoursCost
                                        , "price", $$v)},expression:"\n                                    getServicePricesDetails.ownManHoursCost\n                                        .price\n                                "}})],1),_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.precentagesRules,"type":"number","label":`${_vm.$t(
                                    'service-prices:partsDiscountInPercent'
                                )}`},model:{value:(
                                    _vm.getServicePricesDetails
                                        .partsDiscountInPercent.price
                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesDetails
                                        .partsDiscountInPercent, "price", $$v)},expression:"\n                                    getServicePricesDetails\n                                        .partsDiscountInPercent.price\n                                "}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }